<template>

<!-- 档案管理页面 -->
  <div class="container">
    <div class="header-container">
      <div class="add-container">
        <el-input maxlength="11" v-model="getInfoData" placeholder="请输入学生姓名或手机号码"></el-input>
        <el-button class="addUser-button" @click="getUser">
          查询
        </el-button>
        <el-button class="addUser-button" @click="getAll">
          重置
        </el-button>
      </div>
    </div>
    <div class="body-container">
      <el-table
        :data="AdministratorGetAllChildrenData"
        :header-cell-style="{textAlign: 'center'}"
        stripe
        style="width: 100%">
        <el-table-column
          prop="childrenId"
          label="档案ID">
        </el-table-column>
        <el-table-column
          fixed
          prop="childrenName"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="sex"
          label="性别">
        </el-table-column>
        <el-table-column
          prop="phone"
          width="140"
          label="手机号码">
        </el-table-column>
        <el-table-column
          prop="schoolName"
          width="180"
          label="学校">
        </el-table-column>
        <el-table-column
          prop="grade"
          width="120"
          label="年级">
        </el-table-column>
        <el-table-column
          prop="className"
          width="120"
          label="班级">
        </el-table-column>
        <el-table-column
          :formatter="visionData1"
          label="裸眼视力(右/左)"
          width="150">
        </el-table-column>
         <el-table-column
          :formatter="visionData2"
          label="戴镜视力(右/左)"
          width="150">
        </el-table-column>
        <el-table-column
          prop="visionIsNormal"
          label="视力状况">
        </el-table-column>
        <el-table-column
          prop="refractiveRightDs"
          width="150"
          label="屈光_右眼球镜DS">
        </el-table-column>
        <el-table-column
          prop="refractiveRightDc"
          width="150"
          label="屈光_右眼柱镜DC">
        </el-table-column>
        <el-table-column
          prop="refractiveRightAxis"
          width="150"
          label="屈光_左眼轴位Axis">
        </el-table-column>
        <el-table-column
          prop="refractiveLeftDs"
          width="150"
          label="屈光_左眼球镜DS">
        </el-table-column>
        <el-table-column
          prop="refractiveLeftDc"
          width="150"
          label="屈光_左眼柱镜DC">
        </el-table-column>
        <el-table-column
          prop="refractiveLeftAxis"
          width="150"
          label="屈光_左眼轴位Axis">
        </el-table-column>
        <el-table-column
          prop="idCard"
          width="200"
          label="身份证号">
        </el-table-column>
        <el-table-column
          prop="studentId"
          label="学号">
        </el-table-column>
        <el-table-column
          prop="refractiveDate"
          width="120"
          label="屈光测试时间">
        </el-table-column>
        <el-table-column
          prop="visionDate"
          width="120"
          label="视力测试时间">
        </el-table-column>
        <el-table-column
          prop="glasses"
          width="120"
          label="是否佩戴眼镜">
        </el-table-column>
        <el-table-column
          prop="glassesType"
          width="180"
          label="戴镜类型">
        </el-table-column>
        <el-table-column
          prop="visionText"
          label="视力">
        </el-table-column>
        <el-table-column
          prop="refractionText"
          label="屈光">
        </el-table-column>
        <el-table-column
          prop="specialVision"
          width="120"
          label="视力特殊情况">
        </el-table-column>
        <el-table-column
          prop="schoolStatus"
          label="学生状态">
        </el-table-column>
        <el-table-column
          prop="birthday"
          width="180"
          label="生日">
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <router-link :to="{name: 'managementRecordEdit', params: {item: scope.row }}">
              <el-button
                size="mini"
                type="text"
                >修改</el-button>
            </router-link>
            <router-link style="color: #409EFF; text-decoration: none; font-size: 12px;" :to="{name: 'managementStudentTesthistory', params: {
                item: scope.row
              }}">
                查看
              </router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-container">
      <pagination :current-page="show.startPage" :total="show.totalPage"  @currentChange="editStartPage" @sizeChange="sizeChange"></pagination>
    </div>
  </div>
</template>
<script>
// import storage from '@/globals/storage/index.js'
import AdministratorService from '@/globals/service/management/Administrator.js'
import newDate from '@/utils/newDate.js'
export default {
  data () {
    return {
      getInfoData: '',
      AdministratorGetAllChildrenData: [],
      show: {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    visionData1 (row) {
      if (row.leftVisionData === '-' && row.rightVisionData === '-') {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.leftVisionData) {
          left = row.leftVisionData
        }
        if (row.rightVisionData) {
          right = row.rightVisionData
        }
        return right + ' / ' + left
      }
    },
    visionData2 (row) {
      if (row.wearLeftVisionData === '-' && row.wearRightVisionData === '-') {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.wearLeftVisionData) {
          left = row.wearLeftVisionData
        }
        if (row.wearRightVisionData) {
          right = row.wearRightVisionData
        }
        return right + ' / ' + left
      }
    },
    sizeChange (val) {
      this.show.pageSize = val
      this.getData()
    },
    getAll  () {
      this.getInfoData = ''
      this.show = {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
      this.getData()
    },
    getUser () {
      if (!this.getInfoData) {
        this.$message({
          type: 'info',
          message: '请输入您要搜索的学生姓名或手机号码'
        })
        return
      }
      AdministratorService.AdministratorSearchChildren({ parameter: this.getInfoData + '' }).then(res => {
        this.AdministratorGetAllChildrenData = this.editData(res.data)
        this.show.totalPage = 0
      })
    },
    editStartPage (index) {
      this.show.startPage = index
      this.getData()
    },
    editData (list) {
      const dataList = list.map(item => {
        for (const key in item) {
          if (item[key] === null || item[key] === undefined) {
            item[key] = '-'
          }
        }
        if (item.glasses !== '-') {
          Number(item.glasses) === 0 ? item.glasses = '没有' : item.glasses = '佩戴'
        }
        if (item.visionIsNormal !== '-') {
          Number(item.visionIsNormal) === 0 ? item.visionIsNormal = '异常' : item.visionIsNormal = '正常'
        }
        if (item.glassesType !== '-') {
          switch (Number(item.glassesType)) {
            case 0:
              item.glassesType = '不戴镜'
              break
            case 1:
              item.glassesType = '框架眼镜'
              break
            case 2:
              item.glassesType = '隐形眼镜'
              break
            case 3:
              item.glassesType = '夜戴角膜塑形镜'
              break
          }
        }
        if (item.schoolStatus !== '-') {
          switch (Number(item.schoolStatus)) {
            case 0:
              item.schoolStatus = '在读'
              break
            case 1:
              item.schoolStatus = '毕业'
              break
            case 2:
              item.schoolStatus = '离校'
              break
          }
        }
        if (item.birthday !== '-') {
          item.birthday = newDate.formatDate(new Date(Number(item.birthday)))
        }
        if (item.visionDate !== '-') {
          item.visionDate = newDate.formatDate(new Date(Number(item.visionDate)))
        }
        if (item.refractiveDate !== '-') {
          item.refractiveDate = newDate.formatDate(new Date(Number(item.refractiveDate)))
        }
        return item
      })
      return dataList
    },
    getData () {
      AdministratorService.AdministratorGetAllChildren(this.show).then(res => {
        // console.log(res)
        this.AdministratorGetAllChildrenData = this.editData(res.data.list)
        this.show.totalPage = res.data.totalPage
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  min-height: 100%;
  background-color: #fff;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 120px;
    .seek-container{
      display: flex;
      align-items: center;
      .seek-section{
        border: 1px solid #34BBEC;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        color: #333;
        /deep/.el-input__inner{
          border: none !important;
          padding: 0 10px !important;
        }
        .el-icon-search{
          color: #999999;
        }
      }
      .seek-button{
        width: 80px;
        background-color: #34BBEC;
        margin-left: 20px;
        border-radius: 20px;
        color: #fff;
      }
    }
    .add-container{
      display: flex;
      .addUser-button{
        background-color: #34BBEC;
        border-radius: 20px;
        color: #fff;
        margin-left: 10px;
      }
      .el-input{
        width: 300px;
        margin: 0 5px;
      }
    }
  }
  .body-container{
    flex: 1;
    /deep/.el-table td.el-table__cell {
      text-align: center;
    }
  }
  .footer-container{
    display: flex;
    width: 100%;
    padding: 33px 0;
    .el-button{
      margin-right: 160px;
    }
  }
}
</style>
